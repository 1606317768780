<mat-card class="user-card" *ngIf="user" >
    <section class="user-card--header">
      <div class="user-picture"> <img [src]="user.photoUrl" referrerpolicy="no-referrer"></div>
      <div><h1>{{user.name}}</h1></div>
      <div>{{user.email}}</div>
    </section>
    <mat-card-content>
    </mat-card-content>
    <mat-card-actions align="end">
      <button mat-stroked-button (click)="close()">Close</button>
      <button mat-stroked-button color="primary" (click)="signOut()">Sign out</button>
    </mat-card-actions>
</mat-card>