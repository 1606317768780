import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';

import { ProductsService } from 'src/app/services/products.service';
import { ProductEditComponent } from './product-edit/product-edit.component';
import { Product } from 'src/app/interfaces/product.model';
import { ModalComponent } from 'src/app/components/modal/modal.component';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit, OnDestroy {
  product: Product;
  products: Product[];
  private productsSub: Subscription;
  displayedColumns: string[] = ['name', 'price', 'position', 'category', 'delete'];
  dataSource = new MatTableDataSource<Product>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    public dialog: MatDialog,
    private productsService: ProductsService
  ) {

    this.productsSub = this.productsService.getProductsUpdateListener()
      .subscribe((products: Product[]) => {
        console.log('products ', products);
        this.products = products;

        this.dataSource = new MatTableDataSource<Product>(products);
        this.dataSource.paginator = this.paginator;
      });
  }

  ngOnInit() {
    this.productsService.getProducts();
    this.dataSource.paginator = this.paginator;
  }

  addProduct() {
    this.product = {
      id: null,
      name: '',
      price: 0,
      position: 0,
      category: null
    };
    // this.openDialog(this.product);
  }

  editProduct(product: Product) {
    // this.openDialog(product);
  }

  showCategory(stars: number) {
    return new Array(stars);
  }

  deleteProduct(product: Product): void {
    const dialogRef = this.dialog.open(ModalComponent, {
      width: '400px',
      height: '300px',
      data: {
        product: product.name,
        title: 'Delete',
        body: `Are you sure you want to delete ${product.name}`,
        confirmationName: product.id
      }
    });

    dialogRef.afterClosed().subscribe(data => {

      if (!data) {
        return;
      }
  
      this.productsService.deleteProduct(product);
    });
  }

  // openDialog(product: Product): void {
  //   console.log('PRODUCTO --> ', product);
  //   const dialogRef = this.dialog.open(ProductEditComponent, {
  //     width: '80%',
  //     // minHeight: '365px',
  //     data: {
  //       id: product.id,
  //       name: product.name,
  //       category: product.category,
  //       gallery: product.gallery,
  //       country: product.country
  //     }
  //   });

  //   dialogRef.afterClosed().subscribe(dataProduct => {
  //     console.log('The dialog was closed', dataProduct);
  //     if (dataProduct === undefined) {
  //       return;
  //     }
  //     // this.product = dataProduct;
  //     if (!dataProduct.id) {
  //       console.log('NEW PRODUCTO ', dataProduct);
  //       this.productsService.addProduct(dataProduct.name, dataProduct.category, dataProduct.gallery, dataProduct.country);
  //     } else {
  //       console.log('UPDATE PRODUCTO ', dataProduct);
  //       this.productsService.updateProduct( dataProduct.id, dataProduct.name, dataProduct.category, dataProduct.gallery, dataProduct.country);
  //     }
  //   });
  // }

  ngOnDestroy() {
    if (this.productsSub) {
      this.productsSub.unsubscribe();
    }
  }

}
