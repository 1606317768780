import { Routes } from '@angular/router';

// import { HeaderComponent } from './components/header/header.component';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { ProductsComponent } from './admin/products/products.component';
import { ProductEditComponent } from './admin/products/product-edit/product-edit.component';


export const ROUTES: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent},
  { path: 'admin/products', component: ProductsComponent},
  { path: 'admin/product/:productId', component: ProductEditComponent},
  { path: 'admin/create/product', component: ProductEditComponent},
  { path: 'calendar', component: CalendarComponent },
  { path: 'schedule', component: ScheduleComponent },
  { path: '**', redirectTo: '' },
];
