<div class="calendar">
  <div class="header-container">
    <div class="month">December 2018</div>
    <div class="days-container">
      <div class="container">
        <div class="days" *ngFor="let day of days">{{day}}</div>
      </div>
    </div>

  </div>
</div>