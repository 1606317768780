import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/services/products.service';
import { Product } from '../../interfaces/product.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  products: any;
  productsSub: any;
  constructor(private productsService: ProductsService) {
    this.productsSub = this.productsService.getProductsUpdateListener()
      .subscribe((products: [{
        id: string,
        name: string,
        price: number,
        position: number,
        category: number,
        country: string,
        pictures: [{
          _id: string,
          product: any,
          imagePath: string,
          position: number,
          parentId: string,
          parentPicture: string
        }]
      }]) => {
        console.log('products -----> ', products[0].pictures);
        this.products = products;
      });
  }

  ngOnInit() {
    this.getProducts();
  }

  getProducts() {
    this.productsService.getProducts();
  }

}
