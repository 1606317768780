<div class="schedule-container">
  <div class="schedule-container--month">Enero 2019</div>
  
  <div class="schedule-container--days-container"> 
    <div class="schedule-container--days-container--days" *ngFor="let day of days">{{day}}</div>
  </div>
  <div class="schedule-container--days-container"> 
      <div class="schedule-container--days-container--days" *ngFor="let day of days">{{day}}</div>
  </div>
  
  <p>
    schedule works!
  </p>
</div>
