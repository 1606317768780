import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { of, Subject, Observable } from 'rxjs';

import { Product, Gallery } from '../interfaces/product.model';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

const BACKEND_PICTURE_URL= environment.baseUrl + environment.apiUrl + '/pictures';
const BACKEND_URL= `${environment.baseUrl}${environment.apiUrl}/products/`;

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  baseUrl = environment.baseUrl;
  products: Product[];
  private productsUpdated = new Subject<Product[]>();
  
  constructor(private httpClient: HttpClient) { }

  getProductsUpdateListener() {
    return this.productsUpdated.asObservable();
  }

  getProducts() {
    this.httpClient.get<{message: string, products: any}>(BACKEND_URL)
      .pipe(map((data) => {
        return data.products.map(product_ => {
          return {
            id: product_._id,
            name: product_.name,
            price: product_.price ? product_.price : 0,
            position: product_.position ? product_.position : 0,
            category: product_.category,
            pictures: product_.pictures ? product_.pictures : []
          };
        });
      }))
      .subscribe((transformedProducts) => {
        console.log('Response ', transformedProducts);
        this.products = transformedProducts;
        this.productsUpdated.next([...this.products]);
      });
  }

  getProduct(productId: string) {
    return this.httpClient.get<{message: string, product: {_id: string, name: string, price: number, position: number, category: number} }>( BACKEND_URL + productId).pipe(
      map(result => {
        const product: Product = {
          id: result.product._id,
          name: result.product.name,
          price: result.product.price ? result.product.price : 0,
          position: result.product.position ? result.product.position : 0,
          category: result.product.category
        };
          console.log('PRODUCT ', product);
        return product;
      })
    )
  }

  getPicturesProduct(productId: string) {
    const params = new HttpParams()
    .set('parentId', productId)
    .set('parentPicture', 'product');
    console.log('Params ', params);
    return this.httpClient.get<{
      message: string,
      pictures: [{_id: string, name: string, parentId: string, parentPicture: string, position: number, imagePath: string}]}>
      ( BACKEND_PICTURE_URL , { params }).pipe(map((data) => {
        console.log('DATA ', data);
        return data.pictures.map(picture => {
          return {id: picture._id,
            parentId: picture.parentId,
            parentPicture: picture.parentPicture,
            name: picture.name,
            position: picture.position,
            imagePath: picture.imagePath
          };
        });
      })
    );
  }

  deleteProduct(product: Product) {
    const productId = product.id;
    this.httpClient.delete( BACKEND_URL + productId)
      .subscribe((response) => {
        console.log('response ', response);
        this.products = this.products.filter(item => item.id !== productId);
        console.log('productList ', this.products);
        this.productsUpdated.next([...this.products]);
      });
  }

  deletePicture(pictureId: string) {
    return this.httpClient.delete( BACKEND_PICTURE_URL  + `/${pictureId}`);
  }

  updateProduct( id: string, name: string, price: number, position: number, category: number) {
    let productData: Product;

      productData = {id: id, name: name, price: price, position: position, category: category};

    return this.httpClient.put<{message: string, product: Product}>( BACKEND_URL + id, productData)
  }

  addPicture(parentId: string, parentPicture: string, name: string, position: number, picture: File) {
    const pictureData = new FormData();
    pictureData.append('parentId', parentId);
    pictureData.append('parentPicture', parentPicture);
    pictureData.append('name', name);
    pictureData.append('position', position.toString());
    pictureData.append('image', picture, name);

    return this.httpClient.post<{message: string, picture: any}>( BACKEND_PICTURE_URL, pictureData);
  }

  addProduct(name: string, price: number, position: number, category: number) {

    const productData = {name: name, price: price, position: position, category: category};

    console.log('AddProduct ', productData);
    return this.httpClient.post<{message: string, product: Product}>( BACKEND_URL, productData);
  }
}
