<div class="picture-container">
  <form *ngIf="form" [formGroup]="form" (submit)="savePicture()" >
    <div class="form-container">
      <mat-form-field>
          <input matInput placeholder="Name" formControlName="name">
          <mat-error *ngIf="form.get('name').invalid">Please enter the picture name</mat-error>
      </mat-form-field>
    </div>
    <div class="form-container">
      <mat-form-field>
          <input matInput placeholder="Position" formControlName="position">
          <mat-error *ngIf="form.get('position').invalid">Please enter the position</mat-error>
      </mat-form-field>
    </div>
    <div class="picture-action">
      <button mat-stroked-button type="button" (click)="filePicker.click()">Pick Image</button>
      <input type="file" #filePicker (change)="imagePicked($event)">
    </div> 
    
    <div class="image-preview" *ngIf="imagePreview && form.get('picture').valid">
      <img [src]="imagePreview" [alt]="form.value.name">
    </div>
    <div mat-dialog-actions align="end" class="picture-footer">
        <button mat-button mat-raised-button type="button" (click)="cancel()">Cancel</button>
        <button mat-button mat-raised-button color="primary" >Save</button>
      </div>
  </form>
</div>
