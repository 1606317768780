import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-days-month',
  templateUrl: './days-month.component.html',
  styleUrls: ['./days-month.component.scss']
})
export class DaysMonthComponent implements OnInit {
  days: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
  constructor() { }

  ngOnInit() {
  }

}
