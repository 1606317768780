export class Gallery {
    imagePath: string;
    position: Number;
}
export class Product {
  id?: string;
  name: string;
  price: number;
  position: number;
  category: number;
}