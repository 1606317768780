import { Injectable } from '@angular/core';
// import { Socket } from 'ngx-socket-io';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable()
export class DirectMessageService {

  // constructor(private socket: Socket) { }

  getUpdate() {
    console.log('GETMESSAGES');
    // const observable = new Observable(observer => {
    //   this.socket.on('NEW', (data) => {
    //     console.log('NEW MESSAGE', data);
    //     observer.next(data);
    //   });
    //   return () => {
    //     this.socket.disconnect();
    //   };
    // });
    // return observable;
  }

  onSendMessage() {
    // console.log('onSendMessage ', this.socket);
    // this.socket.emit('my other event', { id: 'This is my test', doc: '' });
  }
}
