import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
// import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import { ROUTES } from './app.routes';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ResizeService } from './services/resize.service';
import { DirectMessageService } from './services/direct-message.service';
import { HeaderComponent } from './components/header/header.component';

import { HomeComponent } from './components/home/home.component';

import { CalendarHeaderComponent } from './components/calendar/calendar-header/calendar-header.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { DaysMonthComponent } from './components/calendar/calendar-header/days-month/days-month.component';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { HamburgerMenuComponent } from './components/hamburger-menu/hamburger-menu.component';
import { ProductCardComponent } from './components/product/product-card.component';
import { ProductsComponent } from './admin/products/products.component';
import { ProductEditComponent } from './admin/products/product-edit/product-edit.component';
import { AddPictureComponent } from './admin/products/product-edit/add-picture/add-picture.component';
import { ModalComponent } from './components/modal/modal.component';
import { CartComponent } from './components/cart/cart.component';
import { GoogleLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';

import credentials from '../assets/json/credentials.json'
import { UserComponent } from './auth/user/user.component';
// const config: SocketIoConfig = { url: 'http://31.220.61.236:3001', options: {} };
// const configSocket: SocketIoConfig = { url: 'http://localhost:3003', options: {} };
const googleLoginOptions = {
  oneTapEnabled: false, // default is true
  scopes: 'https://www.googleapis.com/auth/calendar.readonly'
}; // https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiauth2clientconfig


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    CalendarComponent,
    CalendarHeaderComponent,
    DaysMonthComponent,
    ScheduleComponent,
    HamburgerMenuComponent,
    ProductCardComponent,
    ProductsComponent,
    ProductEditComponent,
    AddPictureComponent,
    ModalComponent,
    CartComponent,
    UserComponent,
  ],
  // entryComponents: [
  //   ProductEditComponent,
  //   AddPictureComponent,
  //   ModalComponent],
  imports: [
    BrowserModule,
    MatCardModule,
    MatButtonModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatStepperModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatSnackBarModule,
    MatChipsModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatSliderModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(ROUTES, { scrollPositionRestoration: 'enabled' }),
    // SocketIoModule.forRoot(configSocket)
  ],
  providers: [
    ResizeService,
    MatDatepickerModule,
    DirectMessageService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              credentials.web.client_id,
              googleLoginOptions
            )
          },
        ],
        onError: (err) => {
          
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
