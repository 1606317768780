import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { SocialUser } from "@abacritt/angularx-social-login";
import { SocialAuthService } from "@abacritt/angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "@abacritt/angularx-social-login";
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  @Output() clickClose = new EventEmitter<null>();
  private accessToken = '';
  user: SocialUser | null = null;
  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.authService.loggedIn.subscribe((user) => {
      this.user = user;
    });
  }

  close(): void {
    this.clickClose.emit();
  }

  signOut(): void {
    this.authService.signOut();
  }


}
