import { Component, OnInit, Inject } from '@angular/core';
import { mimeType } from '../mine-type.validator';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductsService } from 'src/app/services/products.service';
import { DialogData } from 'src/app/interfaces/dialog-data';
import { Product } from 'src/app/interfaces/product.model';

@Component({
  selector: 'app-add-picture',
  templateUrl: './add-picture.component.html',
  styleUrls: ['./add-picture.component.scss']
})
export class AddPictureComponent implements OnInit {
  imagePreview: any;
  form: UntypedFormGroup;
  constructor(
    private productsService: ProductsService,
    public dialogRef: MatDialogRef<AddPictureComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Product) { }

  ngOnInit() {
    this.imagePreview = null;
    this.createForm( );
  }

  createForm( ) {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(null, {
        validators: [Validators.required, Validators.maxLength(30)]
      }),
      position: new UntypedFormControl(null, {
        validators: [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]
      }),
      picture: new UntypedFormControl(null, {
        validators: [Validators.required],
        asyncValidators: [mimeType]
      })
    });
    console.log('set Value ', this.form);
    this.form.setValue({
      name: '',
      position: 1,
      picture: this.imagePreview
    });
  }

  imagePicked(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.form.patchValue({picture: file});
    console.log('file ', file);
    // console.log('form ', this.form);
    // this.form.get('gallery').updateValueAndValidity();
    const reader = new FileReader();
    reader.onload = () => {
      console.log('imagePreview ', reader.result);
      this.imagePreview = reader.result;
    };
    console.log('file ------------------> ', file);
    reader.readAsDataURL(file);
  }

  cancel() {
    this.dialogRef.close(this.data.id);
  }

  uploadPictureAndSave(productId: string) {
    this.productsService.addPicture(productId, 'product', this.form.value.name, this.form.value.position, this.form.value.picture)
      .subscribe(result => {
        console.log('Result ', result);
        this.dialogRef.close(this.data.id);
      });
  }

  savePicture() {
    if (this.form.invalid) {
      return;
    }
    console.log('data ', this.data)

    if(!this.data.id) {
      this.productsService.addProduct(this.data.name, this.data.price, this.data.position, this.data.category)
          .subscribe((responseData) => {
          console.log('response ', responseData);
          this.data.id = responseData.product.id;
          // this.router.navigate(['/admin/products']);
          this.uploadPictureAndSave(responseData.product.id)
        });
    } else {
      this.uploadPictureAndSave(this.data.id)
    }

    
  }

}
