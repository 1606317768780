import { SocialUser } from '@abacritt/angularx-social-login';
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  currentUrl;
  home = false;
  showUserCard = false;
  loggedIn: boolean = false;
  user: SocialUser | null = null;
  scrollPosition = 0;

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {
    router.events.subscribe((url: any) => {
      this.currentUrl = this.router.url;
      console.log('this.currentUrl ', this.currentUrl);
      this.home = this.currentUrl === '/home';
    });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
  //In chrome and some browser scroll is given to body tag
  let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
  let max = document.documentElement.scrollHeight;
  this.scrollPosition = window.pageYOffset;
  console.log('Scroll Event ', window.pageYOffset );
  console.log('document.documentElement.scrollTop ', document.documentElement.scrollTop);
  console.log('document.body.scrollTop ', document.body.scrollTop);
  console.log('document.documentElement.offsetHeight ', document.documentElement.offsetHeight);
  console.log('pos ', pos);
  // pos/max will give you the distance between scroll bottom and and bottom of screen in percentage.
  if(pos == max )   {
  //Do your action here
  }
  }

  ngOnInit() {
    this.authService.loggedIn.subscribe((user) => {
      this.user = user;

      this.loggedIn = (user != null);

      console.log('loggedIn ', this.loggedIn)

      if (!this.loggedIn) {
        this.showUserCard = false;
      }
    })
  }

  openWhatsApp() {
    window.location.href="http://wa.me/19545448037"
  }

  onShow() {
    return this.currentUrl !== '/login' && this.currentUrl !== '/signup' ;
  }

  openUserCard(): void {
    this.showUserCard = true;
  }

}
