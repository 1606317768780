// import { Component, OnInit, Inject } from '@angular/core';
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { DialogData } from 'src/app/interfaces/dialog-data';

// @Component({
//   selector: 'app-modal',
//   templateUrl: './modal.component.html',
//   styleUrls: ['./modal.component.scss']
// })
// export class ModalComponent implements OnInit {
//   title: string;
//   body: string;
//   constructor(public dialogRef: MatDialogRef<ModalComponent>,
//     @Inject(MAT_DIALOG_DATA) public data: DialogData) {
//       this.title = data['title'];
//       this.body = data['body'];
//     }

//   ngOnInit() {
//   }

//   cancel() {
//     this.dialogRef.close();
//   }

//   accept() {
//     this.dialogRef.close(this.data);
//   }

// }
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from 'src/app/interfaces/dialog-data';

import { AbstractControl } from '@angular/forms';

export function nameConfirmationValidator(): ValidatorFn {
  return (control: AbstractControl) : ValidationErrors | null => {
    const parent = control.parent?.controls as {name: AbstractControl, confirmName: AbstractControl}
    if (parent) {

      // const value = control.value;
      if (control.value && parent.confirmName?.value === control.value) {
          return {};
      }
      
      return {notMatching: true};
    }
    return {}
      
  }
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  title: string;
  body: string;
  confirmationName: string;
  form!: FormGroup;
  constructor(public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      this.title = data.title;
      this.body = data.body;
      this.confirmationName = data.confirmationName.slice(0, 5);
    }

  ngOnInit() {
   this.createForm()
  }

  createForm() {
    this.form = new FormGroup({
      name: new FormControl(null, {
        validators: [Validators.required, Validators.maxLength(150), nameConfirmationValidator()]
      }),
      confirmName: new FormControl(this.confirmationName, {
        validators: [Validators.required, Validators.maxLength(150) ]
      })
    } );
  }

  cancel() {
    this.dialogRef.close();
  }

  accept() {

    if (this.form.invalid) {
      return
    }
    if (this.form.valid && this.confirmationName === this.form.get('name')?.value) {
      this.dialogRef.close(this.data);
    }
  }

}

