import { Component, OnInit, Input } from '@angular/core';
import { Product } from '../../interfaces/product.model';
import { environment } from 'src/environments/environment';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {
  baseUrl = environment.baseUrl;
  @Input() product: {
    id: string,
    name: string,
    price: number,
    category: number,
    pictures: [{
      _id: string,
      product: any,
      imagePath: string,
      position: number,
      parentId: string,
      parentPicture: string
    }]
  };
  imageLoader = true;
  currentPicture: number;
  constructor() { }

  ngOnInit() {
    this.currentPicture = 0;
  }

  showCategory(stars: number) {
    return new Array(stars);
  }

  showPicturesLength(pictures: any[]) {
    return new Array(pictures.length);
  }

  previusPicture(pictures: any[]) {
    this.imageLoader = true;
    if (this.currentPicture > 0) {
      this.currentPicture--;
    } else {
      this.imageLoader = false;
    }
  }

  showPicture(index: number) {
    this.imageLoader = this.currentPicture !== index;
    this.currentPicture = index;
  }

  nextPicture(pictures: any[]) {
    this.imageLoader = true;
    console.log('pictures ', pictures);
    if (pictures.length - 1 > this.currentPicture) {
      this.currentPicture++;
      console.log('currentPicture ', this.currentPicture);
    } else {
      this.imageLoader = false;
    }
  }

  quoteIt(productName: string) {
    const request = `Can you give me information of this arrangement ${productName} ${this.baseUrl + this.product.pictures[this.currentPicture].imagePath}`
    window.location.href=`http://wa.me/19545448037?text=${encodeURI(request)}`
  }
}
