import { Component, OnInit, ViewChild, HostListener, ElementRef } from '@angular/core';

@Component({
  selector: 'app-hamburger-menu',
  templateUrl: './hamburger-menu.component.html',
  styleUrls: ['./hamburger-menu.component.scss']
})
export class HamburgerMenuComponent implements OnInit {
  topClose = '';
  middleBottonClose = '';
  constructor(private eRef: ElementRef) { }

  ngOnInit() {
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
      console.log('clicked inside');
    } else {
      console.log('licked outside');
      if (this.topClose !== '') {
        this.topClose = this.topClose === '' ? 'top-close' : '';
        this.middleBottonClose = this.middleBottonClose === '' ? 'middle-bottom-close' : '';
      }
    }
  }

  clickMenu() {
    this.topClose = this.topClose === '' ? 'top-close' : '';
    this.middleBottonClose = this.middleBottonClose === '' ? 'middle-bottom-close' : '';
  }

}
