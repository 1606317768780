<mat-toolbar [color]="(scrollPosition === 0 && home) ? 'intro' : 'primary'" *ngIf="onShow()" [ngClass]="{heightLarge: (scrollPosition === 0 && home)}">
  <app-hamburger-menu [matMenuTriggerFor]="menu" *ngIf="scrollPosition > 0"></app-hamburger-menu>
  <mat-menu #menu="matMenu">
    <button mat-menu-item  routerLink="/login">Log Out</button>
  </mat-menu>
  <span [ngClass]="(scrollPosition === 0 && home) ? 'logoLarge' : 'logoSmall'" routerLink="/home">
    <img [src]="(scrollPosition === 0 && home) ? '../../../assets/logo/logo-annalise-flowers-gold.png' : '../../../assets/logo/logo-annalise-flowers-white.png'" >
  </span>
  
  <asl-google-signin-button *ngIf="!loggedIn" type='icon' size='medium' shape='circle'></asl-google-signin-button>
  <div *ngIf="loggedIn" class="user-picture"> 
    <button (click)="openUserCard()" class="user-picture--button">
      <img [src]="user?.photoUrl"  referrerpolicy="no-referrer">
    </button>
  </div>
  
  <ul *ngIf="scrollPosition > 0">
    <li>
      <button mat-icon-button color="accent">
        <a href="http://wa.me/19545448037">
          <img [src]="'../../../assets/whatsapp-icon.png'"  class="whatsapp-icon">
        </a>
      </button>
    </li>
    <li>
    </li>
  </ul>
</mat-toolbar>

<app-user *ngIf="showUserCard" (outsideClick)="showUserCard = false" (clickClose)="showUserCard = false"></app-user>

