<div class="modal-container">
  <div class="title">{{title}}</div>
  <div class="body">{{body}}</div>

  <div>
    <p>To confirm ingrese this value</p>
    <p class="confirmation-text">{{confirmationName}}</p>
  </div>
  <form [formGroup]="form">
    <mat-form-field>
      <input matInput placeholder="Name" formControlName="name">
      <mat-error *ngIf="form.get('name')?.errors?.['required']">Please enter the value</mat-error>
      <mat-error *ngIf="form.get('name')?.errors?.['notMatching']">Value not match</mat-error>
    </mat-form-field>
  </form>
  <mat-action-row>
    <button mat-button color="warn" type="button" (click)="cancel()">Cancel</button>
    <button mat-button color="primary" type="button" (click)="accept()">Acept</button>
  </mat-action-row>
</div>

