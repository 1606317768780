<div class="container">
    <div class="title">
        <button mat-raised-button color="primary" [routerLink]="['/admin/create/product']" >Add Product</button>
    </div>
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource">
    
        <!-- Name Column -->
        <ng-container matColumnDef="name" >
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element" [routerLink]="['/admin/product', element.id]" routerLinkActive="router-link-active"  class="column-name"> {{element.name}} </td>
        </ng-container>

        <!-- Price Column -->
        <ng-container matColumnDef="price" >
          <th mat-header-cell *matHeaderCellDef> Price </th>
          <td mat-cell *matCellDef="let element" [routerLink]="['/admin/product', element.id]" routerLinkActive="router-link-active"  class="column-name"> {{element.price}} </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="position" >
          <th mat-header-cell *matHeaderCellDef> Position </th>
          <td mat-cell *matCellDef="let element" [routerLink]="['/admin/product', element.id]" routerLinkActive="router-link-active"  class="column-name"> {{element.position}} </td>
        </ng-container>

        <!-- Category Column -->
        <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef> Category </th>
          <td mat-cell *matCellDef="let element">
            <mat-card-subtitle>
                <i class="material-icons gold md-16" *ngFor="let star of showCategory(element.category)">star</i>
            </mat-card-subtitle>
          </td>
        </ng-container>
    
         <!-- Delete Column -->
         <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef> Delete </th>
          <td mat-cell *matCellDef="let element">
            <mat-card-subtitle>
                <button mat-icon-button type="button" (click)="deleteProduct(element)">
                  <i class="material-icons black md-16" >delete_forever</i>
                </button>
            </mat-card-subtitle>
          </td>
        </ng-container>
    
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    
      <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>
</div>
