<div class="section">
  <!-- <h1>Annalise Flowers to give to the most special</h1>
  <h2>Trust in our excellent service.</h2>
  <div class="join-section"  >Order your Flowers today</div> -->
  <div class="video-continer">
    <!-- <video playsinline loop autoplay [muted]="true" preload="auto" class="video-size">
      <source src="../../../assets/video/footage-annalise.mp4" type="video/mp4"> 
    </video> -->
    <img src="../../../assets/logo/logo-annalise-flowers-gold.png" >
  </div>
</div>
<div class="products-container">
  <app-product-card *ngFor="let product of products" [product]="product"></app-product-card>
</div>

