import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { FacebookLoginProvider, GoogleLoginProvider } from "@abacritt/angularx-social-login";
import jwt_decode from "jwt-decode";

import { SocialUser } from "@abacritt/angularx-social-login";
import { SocialAuthService } from "@abacritt/angularx-social-login";
import { BehaviorSubject, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Roles } from './user-roles';

const BACKEND_URL = environment.baseUrl + environment.apiUrl + '/users/';

// const googleLoginOptions = {
//   oneTapEnabled: true, // default is true
//   scopes: 'https://www.googleapis.com/auth/calendar.readonly'
// }; // https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiauth2clientconfig

type UserToken = {
  aud: string, azp: string, email: string, email_verified: boolean,
  exp: number, family_name: string, given_name: string, iat: number, iss: string, jti: string,
  name: string, nbf: number, picture: string, sub: string
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private accessToken = '';
  private initialized = false;
  user: SocialUser | null = null;
  userPictureUrl = '';
  showUserCard = false;
  private _loggedIn: BehaviorSubject<SocialUser | null> = new BehaviorSubject(this.user);

  get loggedIn(): Observable<SocialUser | null> {
    return this._loggedIn.asObservable();
  }

  constructor(
    private socialAuthService: SocialAuthService, 
    private httpClient: HttpClient,
    private snackBar: MatSnackBar,
  ) { 
    var token = localStorage.getItem('token');
    
    this.accessToken = token !== null ? token : '';
    if (this.accessToken !== '') {
      this.updateUserDataFromToken(this.decodeJwt(this.accessToken))
    }
    
    this.socialAuthService.authState.subscribe((user) => {
      
      if (user !== null) {
        this.getUser(user.email).subscribe(response => {

          if(['User successfully found!', 'User successfully saved!'].includes(response.messge)) {
            this.updateUserStatus(user, response.userTokenLevel)
          } else {
            
              this.snackBar.open(response.messge, 'Error', {
                duration: 2000,
              });
            
            this.updateUserStatus(null, null)
          }

        })
      } else {
        this.updateUserStatus(user, null)
      }
    });

  }

  updateUserStatus(user: any, userTokenLevel: any):void {
    this.user = user;
    this._loggedIn.next(user)
    if (this.user) {
      this.initialized = true;
      this.saveUserToken(this.user, userTokenLevel)
    }
  }

  addUser(id: string, name: string, firstName: string, lastName: string, email: string, photoUrl: string, provider: string) {
  
    const userData = {id: id, name: name, firstName: firstName, lastName: lastName, email: email, photoUrl: photoUrl, provider: provider};

    return this.httpClient.post<{message: string, user: SocialUser}>( BACKEND_URL, userData);
  }

  getUser(email: string) {
    return this.httpClient.get<{message: string, user: SocialUser, token: string }>( BACKEND_URL + 'byEmail/' + email).pipe(
      map(response => {
        let user: SocialUser | null = null;
        if (response.user !== null) {
          user = {
            id: response.user.id,
            name: response.user.name,
            firstName: response.user.firstName,
            lastName: response.user.lastName,
            email: response.user.email,
            photoUrl: response.user.photoUrl,
            provider: response.user.provider,
            authToken: '', 
            idToken: '', 
            authorizationCode: '',
            response: '' 
          } ;
        }
        return {messge: response.message, user, userTokenLevel: response.token};
      })
    );
  }

  private updateUserDataFromToken(userToken: UserToken) :void {
    
    const id = localStorage.getItem('id')
        
    this.user = {
      email: userToken.email,
      firstName: userToken.given_name,
      id: id ? id : '',
      idToken: this.accessToken,
      lastName: userToken.family_name,
      name: userToken.name,
      photoUrl: userToken.picture,
      authToken: this.accessToken,
      provider: "GOOGLE",
      authorizationCode: '',
      response: ''
    } 
    this._loggedIn.next(this.user)
  }

  getUserLevel() :string {
    const userTokenLevel = localStorage.getItem('userTokenLevel')
  
    const decodeUserLevel = userTokenLevel !== null ? jwt_decode(userTokenLevel) as {role: string} : {role: 'user'};

    return decodeUserLevel ? decodeUserLevel?.role : Roles.BUYER 
  }


  private saveUserToken(user: SocialUser, userTokenLevel: string) :void {
    localStorage.setItem('token', user.idToken)
    localStorage.setItem('id', user.id)
    localStorage.setItem('userTokenLevel', userTokenLevel)
    // this.getUserLevel()
  }

  private decodeJwt(idToken: string): UserToken {
    const base64Url = idToken.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window.atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  signOut(): void {

    if(this.initialized) {
      
      this.socialAuthService.signOut()
    } else {
      this.user = null;
    }
    localStorage.removeItem('token')
    localStorage.removeItem('id')
    localStorage.removeItem('userTokenLevel')
    this._loggedIn.next(this.user)
  }

  getAccessToken(): void {
    this.socialAuthService.getAccessToken(GoogleLoginProvider.PROVIDER_ID).then(accessToken => {
      console.log('accessToken ', accessToken)
      this.accessToken = accessToken
    });
  }

  // getGoogleCalendarData(): void {
  //   if (!this.accessToken) return;

  //   this.httpClient
  //     .get('https://www.googleapis.com/calendar/v3/calendars/primary/events', {
  //       headers: { Authorization: `Bearer ${this.accessToken}` },
  //     })
  //     .subscribe((events) => {
  //       alert('Look at your console');
        
  //     });
  // }

  refreshToken(): void {
    this.socialAuthService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
  }

}
