<mat-card class="product-container">
  <ng-container *ngIf="product.pictures && product.pictures.length > 0">
    <div class="picture">
      <i class="material-icons md-36 arrow_left" (click)="previusPicture(product.pictures)" *ngIf="product.pictures && product.pictures.length > 1">keyboard_arrow_left</i>
      <i class="material-icons md-36 arrow_right" (click)="nextPicture(product.pictures)" *ngIf="product.pictures && product.pictures.length > 1">keyboard_arrow_right</i>
      <div class="picture-points" >
        <ng-container *ngFor="let item of showPicturesLength(product.pictures); let i = index">
          <i class="material-icons md-16 transparent" [ngClass]="{white: currentPicture===i}" (click)="showPicture(i)" *ngIf="product.pictures && product.pictures.length > 1">fiber_manual_record</i>
        </ng-container>
      </div>
      <div class="loading-progress" *ngIf="imageLoader">
        <mat-progress-bar
          class="progress-margin"
          color="primary"
          mode="indeterminate">
        </mat-progress-bar>
      </div>
        <img [src]="baseUrl + product.pictures[currentPicture].imagePath" (load)="imageLoader = false">
    </div>
  </ng-container>
  <div class="product-details">
    <mat-card-title>{{product.name}}</mat-card-title> 
    <mat-card-subtitle>
      <i class="material-icons pink md-16" *ngFor="let star of showCategory(product.category)">local_florist</i>
    </mat-card-subtitle>
    
    <div class="product-tarifa" >
      <span *ngIf="product.price > 0">${{product.price}}</span>
      <button mat-icon-button color="accent" (click)="quoteIt(product.name)">
        <span *ngIf="product.price === 0" class="material-icons white md-20">
          ios_share
        </span>
      </button>
    </div>
  </div>
</mat-card>