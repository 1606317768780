<div class="product-container">
  <div class="title" *ngIf="form">
    <h1>Detail {{form.value.name}}</h1> 
  </div>
  <form *ngIf="form" [formGroup]="form" (submit)="saveProduct()" >
    <mat-accordion class="product-headers-align">
      <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            General Info
          </mat-panel-title>
          <mat-panel-description>
            Name and category
            <mat-icon>account_circle</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="form-container">
          <mat-form-field>
              <input matInput placeholder="Name" formControlName="name">
              <mat-error *ngIf="form.get('name').invalid">Please enter the product name</mat-error>
          </mat-form-field>
          <mat-form-field>
              <input matInput placeholder="Price" formControlName="price">
              <mat-error *ngIf="form.get('price').invalid">Please enter the product price</mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="Position" formControlName="position">
            <mat-error *ngIf="form.get('position').invalid">Please enter the product position</mat-error>
          </mat-form-field>
          
          <div>
            <button mat-icon-button type="button" *ngFor="let index of [1,2,3,4,5]">
              <mat-icon [ngClass]="form.get('category').value >= index ? 'material-icons gold md-16' : 'material-icons black md-16'" (click)="form.controls['category'].setValue(index)">star</mat-icon>
            </button>
          </div>

        </div> 
           
        <mat-action-row>
          <button mat-button color="primary" type="button" (click)="nextStep()">Next</button>
        </mat-action-row>
      </mat-expansion-panel>

      <!-- <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Address
            </mat-panel-title>
            <mat-panel-description>
              Type the country name
              <mat-icon>map</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <mat-form-field>
            <mat-label>Country</mat-label>
            <mat-select formControlName="country">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let country of countries" [value]="country">{{country}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-action-row>
            <button mat-button color="warn" type="button" (click)="prevStep()">Previous</button>
            <button mat-button color="primary" type="button" (click)="nextStep()">Next</button>
          </mat-action-row>
      </mat-expansion-panel> -->

      <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Gallery
            </mat-panel-title>
            <mat-panel-description>
              Add pictures
              <mat-icon>camera_alt</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="gallery-container" >
              <button mat-stroked-button type="button" (click)="addPicture()">Add Picture</button>
              <div class="image-container" >
                <div *ngFor="let picture of pictures"  class="image">
                  <div class="title">{{picture.parentPicture}}</div>
                  <div class="delete">
                    <i class="material-icons" (click)="delelePicture(picture)">clear</i>
                  </div>
                  <div class="picture-position">{{picture.position}}</div>
                  <div class="image-preview" *ngIf="picture">
                    <img [src]="baseUrl + picture.imagePath" [alt]="picture.name">
                  </div>
                </div>
              </div>
          </div>
          
          <mat-action-row>
            <button mat-button color="warn" type="button" (click)="prevStep()">Previous</button>
            <button mat-button color="primary" type="button" (click)="nextStep()">Next</button>
          </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion>
    <div mat-dialog-actions align="end" class="product-footer">
      <button mat-button mat-raised-button (click)="cancel()">Cancel</button>
      <button mat-button mat-raised-button color="primary">Save</button>
    </div>
  </form>
</div>