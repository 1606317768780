import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Product } from 'src/app/interfaces/product.model';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { mimeType } from './mine-type.validator';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { ProductsService } from 'src/app/services/products.service';
import { AddPictureComponent } from './add-picture/add-picture.component';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss']
})
export class ProductEditComponent implements OnInit {
  baseUrl = environment.baseUrl;
  step = 0;
  currentPicture = 0;
  pictures: any = [];
  countries = ['Unites States', 'Dominic Republic', 'Costa Rica', 'Aruba', 'Curacao', 'Venezuela'];
  imagePreview: any = [];
  mode = 'new';
  productId = null;
  form: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private productsService: ProductsService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog ) {}

  ngOnInit() {

    console.log('this.route ', this.route.snapshot.paramMap.get('productId'));
    this.productId = this.route.snapshot.paramMap.get('productId');
    this.mode = this.productId ? 'edit' : 'new';
    console.log('productId ', this.productId);
    console.log('mode ', this.mode);

    if (this.mode === 'edit') {
      this.productsService.getProduct(this.productId).subscribe(result => {
        console.log('product ', result);
        this.createForm(result);
        this.getProductPictures(this.productId);
      });
    } else {
      const product: Product = {
        id: null,
        name: '',
        price: 0,
        position: 0,
        category: 1
      };
      this.createForm(product);
    }

  }

  getProductPictures(productId: string) {
    console.log('Pictures ', productId);
    this.productsService.getPicturesProduct(productId).subscribe(result => {
      console.log('Pictures ', result);
      this.pictures = result;
    });
  }

  createForm(product) {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(null, {
        validators: [Validators.required, Validators.maxLength(30)]
      }),
      price: new UntypedFormControl(null, {
        validators: [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]
      }),
      position: new UntypedFormControl(null, {
        validators: [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.minLength(1)]
      }),
      category: new UntypedFormControl(null, {
        validators: [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.minLength(1), Validators.maxLength(5)]
      }),
    });
    console.log('set Value ', product);
    this.form.setValue({
      name: product.name,
      price: product.price,
      position: product.position,
      category: product.category
    });
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  showCategory(stars: number) {
    return new Array(stars);
  }

  imagePicked(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    this.form.patchValue({gallery: files});
    console.log('file ', files);
    console.log('form ', this.form);
    this.form.get('gallery').updateValueAndValidity();
    const reader = new FileReader();
    reader.onload = () => {
      console.log('imagePreview ', reader.result);
      this.imagePreview.push(reader.result);
    };
    console.log('files ------------------> ', files);
    for (let i = 0; i < files.length; i++) {
      console.log('FILE ---> ', files[i]);
      reader.readAsDataURL(files[i]);
    };
  }

  cancel(): void {
    this.router.navigate(['/admin/products']);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  // delelePicture(picture): void {
  //   const dialogRef = this.dialog.open(ModalComponent, {
  //     width: '400px',
  //     height: '300px',
  //     data: {
  //       picture: picture,
  //       title: 'Delete picture',
  //       body: `Are you sure you want to delete the picture ${picture.name} `
  //     }
  //   });

  //   dialogRef.afterClosed().subscribe(data => {
  //     console.log('The dialog was closed', data);
  //     if (!data) {
  //       return;
  //     }
  //     this.productsService.deletePicture(picture.id).subscribe((response) => {
  //       console.log('delelePicture ', response);
  //       this.pictures = this.pictures.filter(item => item.id !== picture.id);
  //       console.log('pictures ', this.pictures);
  //       this.pictures = [...this.pictures];
  //     });
  //   });
  // }
  // delelePicture(picture: { name: any; id: string; }): void {
  //   const dialogRef = this.dialog.open(ModalComponent, {
  //     width: '400px',
  //     height: '300px',
  //     data: {
  //       picture: picture,
  //       title: 'Delete picture',
  //       body: `Are you sure you want to delete the picture ${picture.name}`,
  //       confirmationName: picture.id
  //     }
  //   });

  //   dialogRef.afterClosed().subscribe(data => {
      
  //     if (!data) {
  //       return;
  //     }
      
  //     this.productsService.deletePicture(picture.id).subscribe((response) => {
  //       console.log('delelePicture ', response);
  //       this.pictures = this.pictures.filter(item => item.id !== picture.id);
  //       console.log('pictures ', this.pictures);
  //       this.pictures = [...this.pictures];
  //     });
  //   });
  // }
  delelePicture(picture: { name: any; id: string; }): void {
    const dialogRef = this.dialog.open(ModalComponent, {
      width: '400px',
      height: '300px',
      data: {
        picture: picture,
        title: 'Delete picture',
        body: `Are you sure you want to delete the picture ${picture.name}`,
        confirmationName: picture.id
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      
      if (!data) {
        return;
      }
      this.productsService.deletePicture(picture.id).subscribe((response) => {
        console.log('delelePicture ', response);
        this.pictures = this.pictures.filter(item => item.id !== picture.id);
        console.log('pictures ', this.pictures);
        this.pictures = [...this.pictures];
      });
    });
  }

  addPicture(): void {
    const product: Product = {
      id: this.productId,
      name: this.form.value.name,
      price: this.form.value.price,
      position: this.form.value.position,
      category: this.form.value.category
    }
    console.log('PRODUCTO --> ', this.productId);
    const dialogRef = this.dialog.open(AddPictureComponent, {
      width: '400px',
      height: '630px',
      data: product
    });

    dialogRef.afterClosed().subscribe(productId => {
      console.log('The dialog was closed', productId);
      this.productId = productId;
      this.mode = this.productId ? 'edit' : 'new';
      this.productsService.getPicturesProduct(this.productId).subscribe(result => {
        console.log('Pictures ', result);
        this.pictures = result;
      });
    });
  }

  saveProduct(): void {
    console.log('this.form ', this.form);
    if (this.form.invalid) {
      return;
    }
    const product: Product = {
      id: this.productId,
      name: this.form.value.name,
      price: this.form.value.price,
      position: this.form.value.position,
      category: this.form.value.category
    };
    switch (this.mode) {
      case 'new': {
        console.log('NEW PRODUCTO ', product);
        this.productsService.addProduct(product.name, product.price, product.position, product.category)
          .subscribe((responseData) => {
          console.log('response ', responseData);
          this.router.navigate(['/admin/products']);
        });
        break;
      }
      case 'edit': {
        console.log('UPDATE PRODUCTO ', product);
        this.productsService.updateProduct( product.id, product.name, product.price, product.position, product.category)
          .subscribe((res) => {
          console.log('updateProduct ', res);

          this.snackBar.open('Product Updated Successfully', 'Updated', {
            duration: 2000,
          });

          this.router.navigate(['/admin/products']);
        });
        break;
      }
    }

  }


}
